import { IUseStateStyles } from './types'
import { useEffect, useMemo, useState } from 'react'

export const useStateStyles: IUseStateStyles = ({
  open,
  closeOnBackdrop,
  styles,
  overrides,
}) => {
  const openStyles = useMemo(() => {
    if (open) return [styles.open, overrides?.open]
    return []
  }, [open, styles.open, overrides?.open])

  const closeOnBackdropStyles = useMemo(() => {
    if (closeOnBackdrop)
      return [styles.closeOnBackdrop, overrides?.closeOnBackdrop]
    return []
  }, [closeOnBackdrop, styles.closeOnBackdrop, overrides?.closeOnBackdrop])

  return { openStyles, closeOnBackdropStyles }
}

export default function useWidth(): number {
  const [width, setWidth] = useState(
    window.innerWidth * (window.visualViewport?.scale || 1),
  )

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth * (window.visualViewport?.scale || 1))
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return width
}
