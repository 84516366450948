import { css } from '@conexasaude/styles'

export const boxSizingReset = css`
  box-sizing: border-box;
  &:before,
  &:after,
  & *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`
