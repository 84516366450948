import { ButtonProps } from './types'
import { FunctionComponent, useMemo } from 'react'
import useStyles from './styles'
import { useValidateProps, useStateStyles } from './utils'
import { Spinner } from '@/components/Spinner'
import { css } from '@conexasaude/styles'

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  styles: overrides,
  size: sizeProp = 'sm',
  disabled = false,
  busy = false,
  variant: variantProp = 'flat',
  fullWidth = false,
  startIcon,
  endIcon,
  active,
  ...props
}) => {
  const { size, variant } = useValidateProps({ sizeProp, variantProp })

  const styles = useStyles({ size, variant, fullWidth, active })
  const { busyStyles, disabledStyles, variantStyles } = useStateStyles({
    styles,
    size,
    disabled,
    busy,
    variant,
    overrides,
  })

  const spinnerSize = useMemo(() => (size === 'sm' ? size : 'md'), [size])

  return (
    <button
      className="hero-button__root"
      {...props}
      data-testid="hero-button"
      disabled={disabled}
      css={[
        styles.root,
        overrides?.root,
        ...variantStyles,
        ...disabledStyles,
        ...busyStyles,
      ]}
    >
      <Spinner
        active={busy}
        styles={{ active: css({ marginRight: 8 }) }}
        size={spinnerSize}
      />
      {startIcon && (
        <span
          className="hero-button__icon hero-button__startIcon"
          css={[
            styles.iconBox,
            overrides?.iconBox,
            styles.startIcon,
            overrides?.startIcon,
          ]}
          data-testid="startIcon"
        >
          {startIcon}
        </span>
      )}
      <span
        className="hero-button__label"
        css={[styles.label, overrides?.label]}
      >
        {children}
      </span>
      {endIcon && (
        <span
          className="hero-button__icon hero-button__endIcon"
          css={[
            styles.iconBox,
            overrides?.iconBox,
            styles.endIcon,
            overrides?.endIcon,
          ]}
          data-testid="endIcon"
        >
          {endIcon}
        </span>
      )}
    </button>
  )
}
